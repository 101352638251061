<template>
    <div class="flex flex-col flex-1 overflow-hidden">
        <PageTitle :title="`Flexibility Search`">
            <div v-if="isAggregator" class="flex items-center py-1.5 space-x-6">
                <router-link
                    to="/contracts/create"
                    v-tooltip="
                        cart.length === 0 ? 'Add a one or more flexibilities to cart to create a contract' : null
                    "
                >
                    <TwButton variant="secondary" :disabled="cart.length === 0">
                        <DocumentTextIcon class="w-5 h-5 mr-2" /> Create Contract
                    </TwButton>
                </router-link>
            </div>
        </PageTitle>
        <!--  v-if="isFiltersOpen" @close-filters="isFiltersOpen = false" -->
        <FiltersMenu :isAggregator="isAggregator" @search="searchAssets" />
        <WalletAlert @showImportWalletModal="handleWallet" v-if="isBlockchainEnabled && !hasWallet" />
        <div class="flex flex-1 overflow-hidden">
            <div class="flex flex-col flex-grow">
                <Scrollbar class="flex flex-col flex-1 p-8 overflow-auto" v-if="assets.length > 0 && !loading">
                    <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
                        <AssetCard
                            v-for="(asset, idx) in sortedAssets"
                            :key="idx"
                            :asset="asset"
                            :inCart="cartAssetIds.includes(asset.id)"
                            :isAggregator="isAggregator"
                            :selectedProvider="selectedProvider"
                            :assetsInContracts="assetsInContracts"
                            @add-to-cart="addToCart"
                            @remove-from-cart="removeFromCart"
                        />
                    </div>
                </Scrollbar>
                <div class="flex flex-grow" v-else-if="!loading">
                    <div class="max-w-xl m-auto">
                        <div class="content-center px-8">
                            <svg-image class="p-4 mt-16 lg:mt-32 text-primary-600" src="/img/empty.svg" />
                        </div>
                        <div class="mx-4">
                            <h1 class="mt-4 text-xl font-medium text-center text-primary-900 md:text-xl sm:mt-8">
                                No devices found
                            </h1>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center flex-grow" v-else>
                    <fulfilling-bouncing-circle-spinner
                        class="ml-4"
                        :animation-duration="3000"
                        :size="40"
                        color="#1d746a"
                    />
                    <span class="mt-4 text-neutral-500">Loading Devices. Please wait...</span>
                </div>
            </div>
        </div>

        <WalletModal
            v-if="showImportWalletModal"
            @cancel="showImportWalletModal = false"
            :type="walletAction"
            :title="walletAction === 'Import' ? 'Import an existing Ethereum wallet' : 'Create an Ethereum wallet'"
            :description="
                walletAction === 'Import'
                    ? 'You can import an existing wallet by entering the private key. In addition, a password is required to be used for signing all contracts within the EXCESS EDR App.'
                    : 'Enter a password to encrypt your wallet. This password will be used for signing all contracts within the EXCESS EDR App.'
            "
            :loadingMessage="
                walletAction === 'Import' ? 'Importing and Encrypting Wallet...' : 'Generating and Encrypting Wallet...'
            "
        />
    </div>
</template>

<script>
import { PageTitle, TwButton, Scrollbar, SvgImage } from '@/components';
import { defineComponent, ref, computed } from 'vue';
import { FiltersMenu, AssetCard } from './components';
import { WalletAlert, WalletModal } from '../contract/components';
import { DocumentTextIcon } from '@heroicons/vue/outline';
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';
import { SearchAPI } from '@/api';
import { useCart } from '@/composables';
import store from '@/store';
import * as R from 'ramda';

export default defineComponent({
    name: 'Overview',
    components: {
        PageTitle,
        SvgImage,
        FiltersMenu,
        AssetCard,
        Scrollbar,
        TwButton,
        DocumentTextIcon,
        WalletAlert,
        WalletModal,
        FulfillingBouncingCircleSpinner,
    },
    setup() {
        const user = computed(() => store.state.user);
        const providerId = computed(() => user.value && user.value.id);
        const isBlockchainEnabled = ref(!!process.env.VUE_APP_ETH_NODE);
        const hasWallet = computed(() => store.getters.hasWallet);
        const isAggregator = store.getters.isAggregator;

        const { addToCart, removeFromCart, assetsInContracts } = useCart();

        const cart = computed(() => store.state.cart);
        const cartAssetIds = computed(() => cart.value.map(asset => asset.id));
        const isCartOpen = ref(false);
        const assets = ref([]);
        const searchPayload = ref({});
        const showImportWalletModal = ref(false);
        const loading = ref(false);

        if (isAggregator) {
            searchPayload.value.marketAvailability = true;
        } else {
            searchPayload.value.providerId = providerId.value;
        }

        const format = value => {
            return parseInt(value, 10) < 10 ? `0${value}` : value;
        };

        const convertToNumber = value => {
            return value !== null && value !== '' ? Number(value) : null;
        };

        const selectedProvider = computed(() => (cart.value.length > 0 ? cart.value[0].providerId : null));

        const prepareSearchPayload = filters => {
            const payload = R.clone({ ...searchPayload.value, ...filters });

            payload.nominalPowerRange.min = convertToNumber(payload.nominalPowerRange.min);
            payload.nominalPowerRange.max = payload.nominalPowerRange.min;
            payload.flexibilityCapacityRange.min = convertToNumber(payload.flexibilityCapacityRange.min);
            payload.flexibilityCapacityRange.max = payload.flexibilityCapacityRange.min;
            payload.startTimeRange.min =
                payload.startTimeRange.min.hours === null || payload.startTimeRange.min.minutes === null
                    ? null
                    : `${format(payload.startTimeRange.min.hours)}:${format(payload.startTimeRange.min.minutes)}`;
            payload.startTimeRange.max = payload.startTimeRange.min;
            payload.endTimeRange.min =
                payload.endTimeRange.min.hours === null || payload.endTimeRange.min.minutes === null
                    ? null
                    : `${format(payload.endTimeRange.min.hours)}:${format(payload.endTimeRange.min.minutes)}`;
            payload.endTimeRange.max = payload.endTimeRange.min;
            payload.dailyActivationsRange.min = convertToNumber(payload.dailyActivationsRange.min);
            payload.dailyActivationsRange.max = payload.dailyActivationsRange.min;

            return payload;
        };

        const searchAssets = filters => {
            loading.value = true;
            const payload = Object.keys(filters).length > 0 ? prepareSearchPayload(filters) : searchPayload.value;

            SearchAPI.search(payload)
                .then(res => {
                    assets.value = res.data ? res.data.results : res.data;
                    loading.value = false;
                })
                .catch(() => (loading.value = false));
        };
        const walletAction = ref('Import');
        const handleWallet = action => {
            walletAction.value = action;
            showImportWalletModal.value = true;
        };

        const sortedAssets = computed(() =>
            R.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt), assets.value),
        );
        searchAssets({});

        return {
            selectedProvider,
            isAggregator,
            assets,
            cart,
            isCartOpen,
            cartAssetIds,
            searchAssets,
            addToCart,
            removeFromCart,
            user,
            showImportWalletModal,
            isBlockchainEnabled,
            hasWallet,
            assetsInContracts,
            loading,
            walletAction,
            handleWallet,
            sortedAssets,
        };
    },
});
</script>
