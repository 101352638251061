<template>
    <div class="px-8 py-6 bg-white shadow-md">
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-5" v-if="filterBy === 'asset'">
            <MultiselectField
                label="Device Type"
                placeholder="Select device type"
                name="assetType"
                id="assetType"
                mode="tags"
                :options="assetTypeOptions"
                v-model="filters.assetTypes"
            />

            <InputField
                name="nominalPower"
                label="Nominal Power"
                placeholder="Enter nominal power"
                v-model="filters.nominalPowerRange.min"
                type="number"
            />
            <InputField
                name="flexibilityCapacity"
                label="Max Flexibility Capacity"
                placeholder="Enter maximum flexibility capacity"
                v-model="filters.flexibilityCapacityRange.min"
                type="number"
            />
        </div>
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-5" v-else>
            <MultiselectField
                label="Week Day"
                placeholder="Select week day"
                name="weekDay"
                id="weekDay"
                mode="tags"
                :options="weekDayOptions"
                v-model="filters.weekDays"
            />
            <Calendar
                v-model="filters.startTimeRange.min"
                label="Start Time"
                placeholder="Start Time"
                mode="time"
                uid="startTime"
                name="startTime"
            />
            <Calendar
                v-model="filters.endTimeRange.min"
                label="End Time"
                placeholder="End Time"
                mode="time"
                uid="endTime"
                name="endTime"
            />
            <InputField
                name="dailyActivations"
                label="Daily Activations"
                placeholder="Enter daily activations"
                v-model="filters.dailyActivationsRange.min"
                type="number"
            />
        </div>
        <div class="flex items-center justify-end mt-4 space-x-4">
            <a
                href="javascript:void(0)"
                class="text-sm text-primary-500 hover:text-primary-700"
                v-if="isFiltered"
                @click="clearFilters"
                >Clear Filters</a
            >
            <TwButton size="xs" @click="search" :disabled="!filtersChanged">Search</TwButton>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { InputField, TwButton, MultiselectField, Calendar } from '@/components';
import * as R from 'ramda';

export default defineComponent({
    name: 'FiltersMenu',
    components: {
        InputField,
        TwButton,
        Calendar,
        MultiselectField,
    },
    props: {
        isAggregator: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const assetTypeOptions = ['HVAC']; // TODO: maybe for next deployment 'Lights'
        const weekDayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const isFiltered = ref(false);
        const filtersCleared = ref(false);
        const filterBy = ref('asset');
        const filterOptions = [
            {
                name: 'asset',
                value: 'asset',
                label: 'Asset Properties',
            },
            {
                name: 'business',
                value: 'business',
                label: 'Business Properties',
            },
        ];
        const emptyFilters = {
            assetTypes: [],
            flexibilityCapacityRange: {
                min: null,
                max: null,
            },
            nominalPowerRange: {
                min: null,
                max: null,
            },
            weekDays: [],
            startTimeRange: {
                min: {
                    hours: null,
                    minutes: null,
                },
                max: {
                    hours: null,
                    minutes: null,
                },
            },
            endTimeRange: {
                min: {
                    hours: null,
                    minutes: null,
                },
                max: {
                    hours: null,
                    minutes: null,
                },
            },
            dailyActivationsRange: {
                min: null,
                max: null,
            },
        };
        const filters = ref(R.clone({ ...emptyFilters }));
        const previousFilters = ref(R.clone({ ...emptyFilters }));

        const filtersChanged = computed(() => {
            return JSON.stringify(filters.value) !== JSON.stringify(previousFilters.value) || filtersCleared.value;
        });

        const search = () => {
            filtersCleared.value = false;
            if (filtersChanged.value) isFiltered.value = true;
            previousFilters.value = R.clone({ ...filters.value });
            emit('search', filters.value);
        };

        const clearFilters = () => {
            filters.value = R.clone({ ...emptyFilters });
            previousFilters.value = R.clone({ ...emptyFilters });
            isFiltered.value = false;
            filtersCleared.value = true;
        };

        return {
            filters,
            assetTypeOptions,
            weekDayOptions,
            isFiltered,
            filterOptions,
            filterBy,
            search,
            filtersChanged,
            previousFilters,
            clearFilters,
        };
    },
});
</script>
