<template>
    <div class="flex">
        <div class="max-w-xl m-auto">
            <div class="content-center px-8">
                <svg-image class="p-4 mt-16 lg:mt-32 text-primary-600" src="/img/500.svg" />
            </div>
            <div class="mx-4">
                <h1 class="mt-8 text-4xl font-semibold text-center text-primary-900 md:text-5xl sm:mt-16">
                    Internal server error!
                </h1>
                <p class="mt-2 text-center text-neutral-700 md:text-lg sm:mt-4">
                    Oops, something went wrong! The server encountered an internal error or misconfiguration and was
                    unable to complete your request. Try going back to the previous page or go to
                    <router-link to="/overview" class="text-primary-600 underline hover:text-primary-400"
                        >Overview</router-link
                    >
                    page to start over.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { SvgImage } from '@/components';

export default defineComponent({
    name: 'Error500',
    components: { SvgImage },
});
</script>
